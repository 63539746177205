import React from 'react';
import L, { LatLngExpression } from 'leaflet';
import { Marker, Popup, Tooltip } from 'react-leaflet';
import { getPinDivIcon } from 'src/app/maps/components/mapMarkerHelpers';
import { availableAreaColors } from 'src/shared/constants/colors';
import MarkerInfo from '../marker-info/MarkerInfo';
import { SurveyDesignSample } from 'src/app/surveys/views/new-survey-design/state/newSurveyDesignSlice';
import styles from './SampleMapMarker.module.scss';

type SampleMapMarkerProps = {
    markerRefs: React.MutableRefObject<L.Marker[]>;
    index: number;
    sample: SurveyDesignSample | null;
    sampleGroups: string[];
    markerEventHandlers: (index: number) => {
        click: () => void;
        popupclose: () => void;
        popupopen: () => void;
        mouseover: () => false | void;
        mouseout: () => void;
    };
    hoverSampleId: number | null;
};
const SampleMapMarker = ({ markerRefs, sample, index, sampleGroups, hoverSampleId, markerEventHandlers }: SampleMapMarkerProps) => {
    const defaultSampleInfo = {
        habitatAssayTypes: [],
        kitType: '',
        latitude: '',
        longitude: '',
        sampleGroup: '',
        sampleId: '',
        samplePoint: '',
    };

    const samplePinIcon = getPinDivIcon({
        color: sample ? availableAreaColors[sampleGroups.indexOf(sample.sampleGroup)] : 'grey',
        groupOrder: 1,
        isSurveyPin: false,
    });
    return (
        <Marker
            data-testid={sample?.sampleId}
            ref={(ref: L.Marker) => (markerRefs.current[index] = ref)}
            key={index}
            position={sample ? ([sample.latitude, sample.longitude].map(Number) as LatLngExpression) : [0, 0]}
            icon={samplePinIcon}
            eventHandlers={markerEventHandlers(index)}
        >
            {hoverSampleId === index && (
                <Tooltip direction='top' offset={[0, -6]} opacity={1} className={styles.tooltip}>
                    <MarkerInfo sample={sample || defaultSampleInfo} />
                </Tooltip>
            )}

            <Popup autoPan={true} offset={[0, -6]}>
                {sample && <MarkerInfo sample={sample} />}
            </Popup>
        </Marker>
    );
};

export default SampleMapMarker;
