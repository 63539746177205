import { useState, useEffect, useRef } from 'react';
import L from 'leaflet';
const useMapMarkers = () => {
    const mapRef = useRef<L.Map>(null);
    const [activeSampleId, setActiveSampleId] = useState<number | null>(null);
    const [hoverSampleId, setHoveredSampleId] = useState<number | null>(null);
    const markerRefs = useRef<L.Marker[]>([]);

    useEffect(() => {
        if (!markerRefs.current || activeSampleId === null) {
            markerRefs.current.forEach(marker => marker.closePopup());
            return;
        }

        markerRefs.current[activeSampleId].openPopup();
        markerRefs.current[activeSampleId].setOpacity(1);
        markerRefs.current.forEach((marker, i) => {
            const opacity = i === activeSampleId ? 1 : 0.25;
            marker.setOpacity(opacity);
        });
    }, [activeSampleId]);

    const markerEventHandlers = (index: number) => ({
        click: () => {
            return activeSampleId !== index ? (setHoveredSampleId(null), setActiveSampleId(index)) : setActiveSampleId(null);
        },
        popupclose: () => {
            markerRefs.current.forEach(marker => marker.setOpacity(1));
        },
        popupopen: () => {
            markerRefs.current.forEach((marker, i) => {
                const opacity = i === activeSampleId ? 1 : 0.25;
                marker.setOpacity(opacity);
            });
        },
        mouseover: () => {
            return activeSampleId !== index && setHoveredSampleId(index);
        },
        mouseout: () => setHoveredSampleId(null),
    });

    return { mapRef, activeSampleId, setActiveSampleId, hoverSampleId, markerRefs, markerEventHandlers };
};

export default useMapMarkers;
