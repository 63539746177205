import { divIcon, PointExpression } from 'leaflet';

export type GetIsMarkerOpaqueParams = {
    activeSampleId: string | null;
    focusedSpeciesScientificName: string | null;
    currentSampleId: string;
    sampleIdsInFocus: string[];
};

export type GetPinDivIconParams = {
    color: string;
    groupOrder: number;
    isSurveyPin?: boolean;
};

export const getIsMarkerOpaque = (params: GetIsMarkerOpaqueParams) => {
    const { activeSampleId, focusedSpeciesScientificName, currentSampleId, sampleIdsInFocus } = params;

    if (!activeSampleId && !focusedSpeciesScientificName) {
        return false;
    }

    if (activeSampleId && activeSampleId === currentSampleId) {
        return false;
    }

    if (focusedSpeciesScientificName && sampleIdsInFocus.includes(currentSampleId)) {
        return false;
    }

    return true;
};

export const getCircleAnchorPosition = (groupOrder: number): PointExpression => {
    const defaultPosition = [0, 0] as PointExpression;

    if (groupOrder === 0) {
        return defaultPosition;
    }
    const x = [0, -4, 5].at(groupOrder % 3);

    const y = 4 * Math.ceil(groupOrder / 3) + (groupOrder % 3 === 0 ? 4 : 0) + (groupOrder > 3 ? 4 : 0);

    return [x, y] as PointExpression;
};

export const getCircleIcon = (color: string, markerRadius: number, groupOrder: number) => {
    const doubleMarkerRadius = markerRadius * 2;
    const iconAnchorPosition = getCircleAnchorPosition(groupOrder) as [number, number];
    const popupTooltipAnchorPosition = [0 - iconAnchorPosition[0], -markerRadius + 2 + iconAnchorPosition[0]] as PointExpression;

    return divIcon({
        iconAnchor: [markerRadius + iconAnchorPosition[0], markerRadius + iconAnchorPosition[1]],
        iconSize: [doubleMarkerRadius, doubleMarkerRadius],
        popupAnchor: popupTooltipAnchorPosition,
        tooltipAnchor: popupTooltipAnchorPosition,
        html: `
        <svg width="${doubleMarkerRadius}" height="${doubleMarkerRadius}" viewBox="0 0 ${doubleMarkerRadius} ${doubleMarkerRadius}" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="${markerRadius}" cy="${markerRadius}" r="${markerRadius - 1
            }" stroke="${color}" fill-opacity=".5" stroke-width="2" fill="${color}"/>
        </svg>
            `,
        className: '',
    });
};

export const getAnchorPosition = (groupOrder: number): PointExpression => {
    const defaultPosition = [12, 35] as PointExpression;

    if (groupOrder === 0) {
        return defaultPosition;
    }
    const x = [12, 8, 17].at(groupOrder % 3);

    const y = 35 + 4 * Math.ceil(groupOrder / 3) + (groupOrder % 3 === 0 ? 4 : 0) + (groupOrder > 3 ? 4 : 0);

    return [x, y] as PointExpression;
};

export const getPopupAnchorPosition = (groupOrder: number): PointExpression => {
    const defaultPosition = [1, -30] as PointExpression;

    if (groupOrder === 0) {
        return defaultPosition;
    }
    const x = [1, 5, -5].at(groupOrder % 3);

    const y = -30 - 4 * Math.ceil(groupOrder / 3) - (groupOrder % 3 === 0 ? 4 : 0) - (groupOrder > 3 ? 4 : 0);

    return [x, y] as PointExpression;
};

export const getPinDivIcon = ({ color, groupOrder, isSurveyPin }: GetPinDivIconParams) => {
    const iconAnchorPosition = getAnchorPosition(groupOrder);
    const popupAnchorPosition = getPopupAnchorPosition(groupOrder);

    const surveyPin = `<svg xmlns="http://www.w3.org/2000/svg"  width="25" height="35" viewBox="0 0 20 35" fill="none">
    <path d="M3.54137 4.22824C1.95572 5.88079 0.962519 8.2881 1.00108 10.5524C1.08162 15.2842 3.26575 17.0754 6.75857 23.5878C8.01676 26.4535 9.32992 29.4857 10.5789 34.5079C10.7524 35.2486 10.9217 35.9368 11 35.9964C11.0783 36.0561 11.2476 35.3655 11.4212 34.6247C12.6701 29.6026 13.9832 26.5727 15.2414 23.707C18.7343 17.1946 20.9184 15.4034 20.9989 10.6716C21.0375 8.40731 20.0418 5.99759 18.4562 4.34504C16.6448 2.45728 13.9127 1.06019 11 1.00177C8.08727 0.94331 5.35272 2.34047 3.54137 4.22824Z" fill="white" stroke="${color}"/>
    <path d="M10.2753 17.6233V17.7733H10.4253H11.5746H11.7246V17.6233V16.1931C12.9669 16.0403 14.0315 15.5223 14.9131 14.6407C15.7947 13.759 16.3127 12.6945 16.4656 11.4522H17.8958H18.0458V11.3022V10.1529V10.0029H17.8958H16.4656C16.3127 8.7605 15.7948 7.69342 14.9134 6.80679C14.0318 5.91994 12.9671 5.40402 11.7246 5.26087V3.83179V3.68179H11.5746H10.4253H10.2753V3.83179V5.26087C9.03286 5.40402 7.96813 5.91994 7.08656 6.80679C6.20521 7.69342 5.68725 8.7605 5.53439 10.0029H4.10422H3.95422V10.1529V11.3022V11.4522H4.10422H5.53439C5.68726 12.6945 6.20527 13.759 7.08688 14.6407C7.96849 15.5223 9.03307 16.0403 10.2753 16.1931V17.6233ZM13.8789 13.6065C13.0879 14.3974 12.1312 14.7916 11 14.7916C9.86874 14.7916 8.91203 14.3974 8.12108 13.6065C7.33012 12.8155 6.9359 11.8588 6.9359 10.7276C6.9359 9.5963 7.33012 8.6396 8.12108 7.84865C8.91203 7.05769 9.86874 6.66347 11 6.66347C12.1312 6.66347 13.0879 7.05769 13.8789 7.84865C14.6698 8.6396 15.0641 9.5963 15.0641 10.7276C15.0641 11.8588 14.6698 12.8155 13.8789 13.6065Z" fill="${color}" stroke="${color}" stroke-width="0.3"/>
    </svg>`;

    const samplePin = `
        <svg width="25" height="35" viewBox="0 0 20 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m3.54137 4.22824c-1.58565 1.65255-2.578851 4.05986-2.54029 6.32416.08054 4.7318 2.26467 6.523 5.75749 13.0354 1.25819 2.8657 2.57135 5.8979 3.82033 10.9201.1735.7407.3428 1.4289.4211 1.4885.0783.0597.2476-.6309.4212-1.3717 1.2489-5.0221 2.562-8.052 3.8202-10.9177 3.4929-6.5124 5.677-8.3036 5.7575-13.0354.0386-2.26429-.9571-4.67401-2.5427-6.32656-1.8114-1.88776-4.5435-3.28485-7.4562-3.34327-2.91273-.05846-5.64728 1.3387-7.45863 3.22647z" fill="${color}" stroke="#fff"/>
            <circle cx="10.5" cy="10" r="3" fill="#fff"/>
        </svg>
        `;

    return divIcon({
        iconAnchor: iconAnchorPosition,
        popupAnchor: popupAnchorPosition,
        tooltipAnchor: popupAnchorPosition,
        html: isSurveyPin ? surveyPin : samplePin,
        className: '',
    });
};
