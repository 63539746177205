import React, { useEffect, useState } from 'react';
import { MapOutlined, GridOnOutlined } from '@mui/icons-material';
import classNames from 'classnames';
import { Restore as RestoreIcon } from '@mui/icons-material';
import L, { LatLngBoundsExpression } from 'leaflet';
import Tabs from 'src/shared/components/Tabs/Tabs';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import config from 'src/config';
import { MapContainer, ZoomControl } from 'react-leaflet';
import MapType, { mapTypes } from 'src/shared/components/map-type/MapType';
import googleMapStyles from 'src/app/maps/views/samples/components/samples-map/samplesMapFeaturesConfig';
import Control from 'react-leaflet-custom-control';
import { availableAreaColors } from 'src/shared/constants/colors';
import DataTable from 'src/shared/components/data-table/DataTable';
import { DataTableColumns, DataTableState } from 'src/shared/components/data-table/dataTableTypes';
import { NewSurveyDesignFormState, SurveyDesignSample } from '../../../state/newSurveyDesignSlice';
import SampleMapMarker from 'src/app/maps/components/sample-map-marker/SampleMapMarker';
import useMapMarkers from 'src/app/maps/hooks/useMapMarkers';
import 'src/app/maps/leaflet.css';
import styles from './ReviewSamples.module.scss';

const tabs = [
    {
        id: 'map',
        label: (
            <>
                <MapOutlined /> Map view
            </>
        ),
    },
    {
        id: 'table',
        label: (
            <>
                <GridOnOutlined /> Table view
            </>
        ),
    },
];

type ReviewSamplesProps = Pick<NewSurveyDesignFormState, 'surveySamples' | 'sampleGroups'>;

const ReviewSamples = (props: ReviewSamplesProps) => {
    const [tab, setTab] = useState(tabs[0].id);

    return (
        <div className={styles.reviewSamples}>
            <Tabs
                tabs={tabs}
                onTabChange={setTab}
                selectedTab={tab}
                classNames={{
                    default: styles.tabItem,
                    selected: styles.tabItemSelected,
                    tabsContainer: styles.tabsContainer,
                }}></Tabs>

            {tab === 'map' && <ReviewSamplesMap {...props} />}
            {tab === 'table' && <ReviewSamplesTable {...props} />}
        </div>
    );
};

const ReviewSamplesMap = (props: ReviewSamplesProps) => {
    const { surveySamples, sampleGroups } = props;
    const [mapType, setMapType] = useState<string>(mapTypes[0].value);
    const { mapRef, activeSampleId, setActiveSampleId, hoverSampleId, markerRefs, markerEventHandlers } = useMapMarkers();

    const getBounds = () => {
        return surveySamples.map(sample => [sample.latitude, sample.longitude].map(Number)) as LatLngBoundsExpression;
    };

    return (
        <div className={styles.reviewSamplesMap}>
            <MapContainer
                ref={mapRef}
                bounds={surveySamples.length ? getBounds() : undefined}
                scrollWheelZoom={true}
                style={{ height: '100%', width: '100%' }}
                attributionControl={false}
                maxZoom={19}
                zoomControl={false}>
                <ReactLeafletGoogleLayer
                    key={mapType}
                    apiKey={config.googleMapsJavascriptApiKey}
                    useGoogMapsLoader={true}
                    styles={googleMapStyles}
                    type={mapType as L.gridLayer.GoogleMutantType}></ReactLeafletGoogleLayer>
                <ZoomControl position='topright' />

                <Control position='topright'>
                    <span onClick={() => mapRef.current?.fitBounds(getBounds())} className={styles.restoreContainer}>
                        <RestoreIcon className={styles.restore} style={{ fontSize: '30px', cursor: 'pointer' }} />
                    </span>
                </Control>
                <div className='leaflet-bottom leaflet-left'>
                    <div
                        className={styles.legend}
                        ref={ref => {
                            if (!ref) return;
                            L.DomEvent.disableClickPropagation(ref).disableScrollPropagation(ref);
                        }}>
                        <div className={styles.title}>Key</div>
                        {sampleGroups.map((entry, index) => (
                            <span className={styles.keyLabel} key={index}>
                                <i style={{ background: availableAreaColors[index] }}></i>
                                {entry}
                            </span>
                        ))}
                    </div>
                </div>

                {surveySamples.map((sample, index) => (
                    <SampleMapMarker
                        key={index}
                        markerRefs={markerRefs}
                        index={index}
                        sample={sample}
                        sampleGroups={sampleGroups}
                        markerEventHandlers={markerEventHandlers}
                        hoverSampleId={hoverSampleId}
                    />
                ))}

                <div
                    className='leaflet-bottom leaflet-right'
                    style={{ pointerEvents: 'visible', right: '15px' }}
                    ref={ref => {
                        if (!ref) return;
                        L.DomEvent.disableClickPropagation(ref).disableScrollPropagation(ref);
                    }}>
                    <div className={styles.legend}>
                        <div className={styles.title}>Map Type </div>
                        <MapType handleChange={setMapType} value={mapType} />
                    </div>
                </div>
            </MapContainer>

            <div className={styles.sidebar}>
                <div className={styles.top}>
                    <div className={styles.sidebarTitle}>Sample list</div>
                    <div className={styles.surveyCount}>{surveySamples.length} samples</div>
                </div>
                <div className={styles.list}>
                    {surveySamples.map((item, index) => {
                        const listItemClass = classNames(styles.listItem, activeSampleId === index && styles[`listItemActive`]);
                        return (
                            <div
                                key={index}
                                className={listItemClass}
                                style={{ borderColor: availableAreaColors[sampleGroups.indexOf(item.sampleGroup)] }}
                                onClick={() => (activeSampleId !== index ? setActiveSampleId(index) : setActiveSampleId(null))}>
                                {item.sampleId}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const columns: DataTableColumns = [
    {
        columnId: 'sampleId',
        title: 'Unique Sample ID',
    },
    {
        columnId: 'samplePoint',
        title: 'Sample point',
    },
    {
        columnId: 'latitude',
        title: 'Latitude',
    },
    {
        columnId: 'longitude',
        title: 'Longitude',
    },
    {
        columnId: 'sampleGroup',
        title: 'Sample group',
    },
    {
        columnId: 'Kit type',
        title: 'kitType',
    },
    {
        columnId: 'habitatTestTypes',
        title: 'Test',
    },
];

const ReviewSamplesTable = (props: ReviewSamplesProps) => {
    const { surveySamples, sampleGroups } = props;
    const [tableState, setTableState] = useState<Partial<DataTableState>>({ rowsPerPage: 10 });

    useEffect(() => {
        if (!surveySamples.length) {
            return;
        }
        const tableData: DataTableState['data'] = [];
        surveySamples.forEach((sample: SurveyDesignSample) => {
            const { sampleId, samplePoint, latitude, longitude, sampleGroup, kitType, habitatAssayTypes } = sample;

            const currentRow = [];
            currentRow.push(sampleId);
            currentRow.push(samplePoint);
            currentRow.push(latitude || '-');
            currentRow.push(longitude || '-');
            currentRow.push(
                <div className={styles.sampleGroup}>
                    <div
                        className={styles.sampleGroupColor}
                        style={{ backgroundColor: availableAreaColors[sampleGroups.indexOf(sampleGroup)] }}></div>
                    <div className={styles.sampleGroupName}>{sampleGroup}</div>
                </div>
            );
            currentRow.push(kitType);
            currentRow.push(habitatAssayTypes.map(entry => entry.habitatName + ' ' + entry.assayName).join(', '));
            tableData.push(currentRow);
        });

        setTableState({
            ...tableState,
            data: tableData,
        });
    }, [surveySamples]);

    if (!tableState.data) {
        return null;
    }
    return (
        <div className={styles.reviewSamplesTable}>
            <DataTable state={tableState} columns={columns} emptyStateMessage={'No samples to display.'} />
        </div>
    );
};

export default ReviewSamples;
